import { globalVariables } from "../const";
import queryString from "query-string";
import axios from "axios";

class Service {
  getServices = async (point) => {
    try {
      const service = await fetch(`${globalVariables.endPoint}${point}`);
      const jsonService = await service.json();
      return jsonService;
    } catch (error) {
      console.log("Here 1 Error [class Service in service.api.js]: ", error);
    }
  };

  getLocations = async () => {
    try {
      const service = await fetch(
        `https://formularios.caracoltv.com/json/departamentos`
      );
      const jsonService = await service.json();
      return jsonService;
    } catch (error) {
      console.log("Error [class Service in service.api.js]: ", error);
    }
  };

  getServicesVote = async () => {
    try {
      const service = await fetch(`${globalVariables.endPointVotaciones}`);
      const jsonService = await service.json();
      /* console.log("service", jsonService); */
      return jsonService;
    } catch (error) {
      /* console.log(
        "Error Here 0 [class Service in services.api.js, function => getServicesVote",
        error
      ); */
      return "error";
    }
  };

  postService = async (point, parameters) => {
    /* console.log("Error 03", point, parameters); */
    try {
      // eslint-disable-next-line no-undef
      var data = queryString.stringify(parameters);
      var config = {
        method: "post",
        url: `${globalVariables.endPoint}${point}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };
      const response = await axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          /* console.log("Error 04", error.response.data, error.response.status); */
          if (point !== "account-recovery" && point !== "authenticate") {
            return "error";
          } else {
            return error.response.data;
          }
        });
      return response;
    } catch (error) {
      /* console.log(
        "Error 05 [class Service in service.api.js, function => postService]: ",
        error
      ); */
    }
  };

  postVote = async (parameters, token) => {
    try {
      // eslint-disable-next-line no-undef
      var data = queryString.stringify(parameters);
      var config = {
        method: "post",
        url: `${globalVariables.endPoint}vote`,
        headers: {
          Authorization: token,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: data,
      };

      const response = axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return "error";
        });
      return response;
    } catch (error) {
      console.log("Error Send vote", error);
    }
  };

  checkVote = async (token, pollId) => {
    var config = {
      method: "get",
      url: `${globalVariables.endPoint}check-vote/${pollId}`,
      headers: {
        Authorization: token,
      },
    };

    const response = axios(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response.data;
      });
    return response;
  };

  getServicesAuth = async (point, authVal) => {
    try {
      var config = {
        method: "get",
        url: `${globalVariables.endPoint}${point}`,
        headers: {
          Authorization: authVal,
        },
      };

      const response = await axios(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return "error";
        });
      return response;
    } catch (error) {
      console.log(
        "Error [class Service in service.api.js, function => getServicesAuth]: ",
        error
      );
    }
  };
}

export default Service;
