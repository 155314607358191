import React, { useCallback } from "react";
import { Button } from "@mui/material";
import imageStar from "../../assets/images/star_vote.png";
import imageHeart from "../../assets/images/vote_sucess.png";

const ModalCustom = (props) => {
  const getRandomString = useCallback((length) => {
    let randomChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      result += randomChars.charAt(
        Math.floor(Math.random() * randomChars.length)
      );
    }
    return result;
  }, []);
  /**
   * props.state cases:
   *
   * 1 => Voto realizado.
   * 2 => Error al votar.
   * 3 => Reset token.
   */
  if (props.open) {
    let contentModal;
    switch (props.status) {
      case 1:
        contentModal = (
          <>
            <h2>¡Voto realizado!</h2>
            <p>
              Gracias por participar. Te esperamos en la siguiente prueba para
              más sorpresas.
            </p>
            {/* <img src={imageHeart} alt="Unicef" /> */}
            <div className="contentButton">
              <Button
                onClick={props.close}
                fullWidth
                className="Button"
                variant="contained"
                color="primary"
                style={{ marginTop: 30 }}
              >
                ACEPTAR
              </Button>
            </div>
          </>
        );
        break;
      case 2:
        contentModal = (
          <>
            <h2>Voto no enviado</h2>
            <p>No se pudo enviar tu voto, intenta mas tarde.</p>
            <div className="contentButton">
              <Button
                onClick={props.close}
                fullWidth
                className="Button"
                variant="contained"
                color="primary"
                style={{ marginTop: 30 }}
              >
                ACEPTAR
              </Button>
            </div>
          </>
        );
        break;
      case 3:
        contentModal = (
          <>
            <iframe
              src={`https://donaciones.unicef.org.co/resend-email?${getRandomString(
                2
              )}`}
              sandbox="allow-same-origin allow-forms allow-scripts allow-modals allow-popups allow-popups-to-escape-sandbox"
            ></iframe>
            <div className="contentButton">
              <Button
                onClick={props.close}
                fullWidth
                className="Button"
                variant="contained"
                color="primary"
                style={{ marginTop: 30 }}
              >
                CERRAR
              </Button>
            </div>
          </>
        );
      default:
        break;
    }
    return (
      <div className={`Modal ${props.status == 3 ? "resetToken" : ""}`}>
        <div className="overlay"></div>
        <div className="content">{contentModal}</div>
      </div>
    );
  } else {
    return null;
  }
};

export default ModalCustom;
