import React from "react";
import { Navigate } from "react-router-dom";
// Material
import { TextField, Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
// Services
import Service from "../../api/Service";
// Recaptcha
/* import Reaptcha from "@panalbish/reaptcha-enterprise"; */
// Cookies
import Cookies from "universal-cookie";
// Images
import logo from "../../assets/images/logov2.png";
import Donate from "../../components/Donate/Donate";
// Modal
import ModalCustom from "../../components/Modal/ModalCustom";
// Crypto
import nacl from "tweetnacl";
import util from "tweetnacl-util";
import { globalVariables } from "../../const";
import girl from "../../assets/images/back_kid_mobile.png";

const cookies = new Cookies();

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.captcha = null;
  }

  state = {
    redirect: false,
    withHash: false,
    gCaptchaResponse: null,
    loading: false,
    error: null,
    enabled: true,
    modal: false,
    valuesLogin: null,
  };

  login = async (e) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });
    // await this.captcha.execute();
    let passwordEncrypt = nacl.sign(
      util.decodeUTF8(e.target.elements.code.value),
      util.decodeBase64(globalVariables.publicKey)
    );
    passwordEncrypt = util.encodeBase64(passwordEncrypt);

    /* console.log("Error 00", e.target.elements.document.value, passwordEncrypt); */

    const userVal = {
      username: e.target.elements.document.value,
      password: passwordEncrypt,
    };

    console.log("Error 00", userVal);

    const service = new Service();
    const response = await service.postService("authenticate", userVal);
    /* console.log("Error 01", response, service); */
    if (response.status) {
      /* console.log("Error 02-00", response.status, service); */
      cookies.set("token", response.token, { path: "/", maxAge: 86400 });
      this.setState({ redirect: true, loading: false });
      /* console.log("Error 02", response.status, service); */
    } else {
      let msg = "";
      switch (response.error) {
        case "User does not exist":
          msg = "El correo electrónico ingresado no está registrado.";
          break;
        case "Password incorrect":
          msg = "Contraseña incorrecta.";
          break;
        case "Account inactive":
          msg = "Su cuenta aún no ha sido verificada.";
          break;
        default:
          msg = "El usuario o la contraseña son incorrectas.";
          break;
      }
      this.setState({
        error: msg,
        loading: false,
      });
    }

    // this.setState({
    //     valuesLogin: userVal,
    //     loading: true
    // });
  };

  onVerify = async (recaptchaResponse) => {
    this.state.valuesLogin.gCaptchaResponse = recaptchaResponse;
    const userVal = this.state.valuesLogin;
    const service = new Service();
    const response = await service.postService("authenticate", userVal);
    if (response.status) {
      cookies.set("token", response.token, { path: "/", maxAge: 86400 });
      this.setState({ redirect: true, loading: false });
    } else {
      let msg = "";
      switch (response.error) {
        case "User does not exist":
          msg = "El correo electrónico ingresado no está registrado.";
          break;
        case "Password incorrect":
          msg = "Contraseña incorrecta.";
          break;
        case "Account inactive":
          msg = "Su cuenta aún no ha sido verificada.";
          break;
        default:
          msg = "El usuario o la contraseña son incorrectas.";
          break;
      }
      this.setState({
        error: msg,
        loading: false,
      });
    }
  };

  async componentDidMount() {
    /**
     * Component  Start
     */
    const validateCookie = await cookies.get("user");
    if (validateCookie !== undefined) {
      this.setState({
        redirect: true,
      });
    }
  }

  openModal = () => {
    this.setState({
      modal: true,
    });
  };

  closeModal = () => {
    this.setState({
      modal: false,
    });
  };

  resetToken = async (email) => {
    const parameters = {
      email: email,
    };
    const service = new Service();
    const response = await service.resetToken(parameters);
  };

  render() {
    if (this.state.redirect) {
      return <Navigate to="/" />;
    }
    return (
      <>
        <ModalCustom
          open={this.state.modal}
          close={this.closeModal}
          status={3}
        />
        <div className="mainForm">
          <div className="contentLogos">
            <img className="logo" src={logo} />
          </div>
          <div className="contentForm">
            <div className="contentLeft" style={{ textAlign: "center" }}>
              <div className="textForm">
                <h1>Ingresa</h1>
                <p>
                  Ya recibimos tu donación, ahora solo debes ingresar tu número
                  de cédula y el código de acceso que llegó a tu correo
                  electrónico registrado previamente.
                </p>
              </div>
              <form noValidate autoComplete="off" onSubmit={this.login}>
                <TextField
                  className="Input"
                  id="outlined-basic"
                  label="Número de documento"
                  variant="filled"
                  fullWidth
                  name="document"
                />
                <TextField
                  className="Input"
                  type="password"
                  id="outlined-basic"
                  label="Código de acceso"
                  variant="filled"
                  fullWidth
                  style={{ marginTop: 20 }}
                  name="code"
                />
                <div
                  style={{ color: "#f7aa01", fontSize: 12, textAlign: "left" }}
                >
                  {this.state.error}
                </div>
                <Button
                  fullWidth
                  variant="contained"
                  className="Button"
                  style={{
                    marginTop: 20,
                    display: this.state.enabled != "" ? "inline-flex" : "none",
                  }}
                  type="submit"
                >
                  Ingresa{" "}
                  {this.state.loading ? (
                    <CircularProgress
                      size={24}
                      style={{ marginLeft: 10 }}
                      sx={{ color: "fff" }}
                    />
                  ) : null}
                </Button>
                <div
                  style={{
                    color: "#ffffff",
                    fontSize: 15,
                    textAlign: "center",
                    paddingTop: 15,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={this.openModal}
                  className="btn-access-code"
                >
                  ¿Olvidaste tu código de acceso?
                </div>
              </form>
              {/* <Reaptcha onError={(error) => console.log("Captcha error", error)} ref={e => this.captcha = e} size="invisible" sitekey="6Lcr3gkbAAAAAN5G6TigAMlXJ0UumZorMekLG3AP" enterprise={true} action="login" onVerify={this.onVerify} /> */}
            </div>
            <div className="contentRight">
              <Donate />
            </div>
            <div className="contentImegGirl">
              <img className="girl" src={girl} />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Login;
