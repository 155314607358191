import React from 'react';
import { Link } from 'react-router-dom';

const UserSesion = (props) => {
    return (
        <div className="contentSesionUser">
            {props.fullname === undefined || props.tickets === undefined ? <div style={{ color: '#fff' }}>Cargando...</div> : <div style={{ color: '#fff' }}><span>{`${props.tickets} oportunidades`}</span>{props.fullname}</div>}
            <Link to='/login' className='logout' onClick={props.logout}>Cerrar sesión</Link>
        </div>
    );
};

export default UserSesion;