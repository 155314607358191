import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// Pages
import Login from "../src/screens/Login/Login";
import Home from "./screens/Home/Home";
import Auth from "./screens/Hash/Auth";

// General Styles
import GlobalStyles from "./styles/GlobalStyles";

// DFP
import { DFPSlotsProvider, AdSlot } from "react-dfp";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import PreLanding from "./screens/PreLanding/PreLanding";

// ParticlesJS
/* import Particles from 'react-particles-js';
import ParticlesConfig from './assets/json/particlesjs-config.json'; */

const App = () => {
  return (
    <>
      <DFPSlotsProvider dfpNetworkId="100249493">
        <GlobalStyles />
        <div className="ads mobile-ads">
          <AdSlot
            sizes={[
              [320, 50],
              [300, 50],
            ]}
            adUnit="caracoltv/voyportijuegapormi"
          />
          <div className="ads desktop-ads">
            <AdSlot
              adUnit="caracoltv/voyportijuegapormi"
              sizes={[
                [900, 90],
                [728, 90],
              ]}
            />
          </div>
        </div>
        <Header />
        <div className="mainWrap">
          {/* <Particles
            className="particlesStyles"
            params={ParticlesConfig}
          /> */}
          <Router>
            <Routes>
              {" "}
              {/* Replaced Switch with Routes */}
              <Route path="/rvnot" element={<Login />} />{" "}
              {/* Replaced Switch with Routes */}
              <Route path="/login" element={<Login />} />{" "}
              {/* Updated route syntax */}
              <Route path="/auth/:hash" element={<Auth />} />{" "}
              {/* Updated route syntax */}
              <Route path="/Home" element={<Home />} />{" "}
              {/* Updated route syntax */}
              <Route path="/" element={<Home />} />
              {/* Updated route syntax */}
              <Route path="/Prelanding" element={<PreLanding />} />
            </Routes>
          </Router>
        </div>
        <Footer />
      </DFPSlotsProvider>
    </>
  );
};

export default App;
