import React from "react";
import Service from "../../api/Service";
import { CircularProgress } from "@mui/material";
import logo from "../../assets/images/logo_desktop.png";
import Donate from "../../components/Donate/Donate";
// Cookies
import Cookies from "universal-cookie";
import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";

const service = new Service();
const cookies = new Cookies();
class Auth extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    loading: true,
    loginHash: false,
  };

  loginWithHash = async () => {
    const params = this.props.match.params;
    const response = await service.getServices(
      `authenticate-with-hash/${params.hash}`
    );
    this.setState({
      loading: false,
    });
    if (response.status) {
      await cookies.set("token", response.token, { path: "/" });
      this.setState({
        loginHash: true,
      });
    } else {
      this.setState({
        loginHash: false,
      });
    }
  };

  componentDidMount() {
    this.loginWithHash();
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="loadingHome">
          <CircularProgress size={50} color="ffffff" />
        </div>
      );
    } else if (this.state.loginHash) {
      return <Navigate to="/" />;
    } else {
      return (
        <div className="wrapHome">
          <div className="messages">
            <img className="logo" src={logo} />
            <h2>Este enlace ya expiró</h2>
            <p>El enlace al que intentas ingresar ya se utilizó o expiró.</p>
            <p>
              Si no has votado, te invitamos a iniciar sesión con tu número de
              documento y el código de acceso que recibiste en tu correo.
            </p>
            <Link from="/auth" to="/login" className="Button hash">
              Iniciar sesión
            </Link>
          </div>
          <div className="contentDonateHome">
            <Donate />
          </div>
        </div>
      );
    }
  }
}

export default Auth;
