import React, { Component } from "react";
import logo from "../../assets/images/logov2.png";
import girl from "../../assets/images/back_kid_mobile.png";
import Donate from "../../components/Donate/Donate";

class PreLanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // Definir el estado inicial aquí
    };
  }

  // Método del ciclo de vida: se llama después de que el componente se monta
  componentDidMount() {
    // Código para ejecutar después de que el componente se monta
  }

  // Renderiza el contenido del componente
  render() {
    return (
      <>
        {/* <UserSesion {...this.state.user} logout={this.logout} /> */}
        <div className="InitDesktop">
          <div className="wrapHome">
            <div className="messages">
              <img className="logo" src={logo} />
              <h2>
                Te esperamos el próximo
                <br /> 19 de agosto
                <br /> en Voy por ti, Juega por mí
                <br /> para que participes
                <br /> en nuestras actividades.
              </h2>
              <p>Si aún no has donado para participar ingresa aquí</p>
            </div>
            <div className="contentDonateHome">
              <Donate />
            </div>
          </div>
        </div>
        <div className="InitMobile">
          <div className="wrapHome">
            <div className="mainHome">
              <div className="contentLogos">
                <img className="logo" src={logo} />
              </div>
              <div className="messages">
                <h2>
                  Te esperamos el próximo
                  <br /> 19 de agosto
                  <br /> en Voy por ti, Juega por mí
                  <br /> para que participes
                  <br /> en nuestras actividades.
                </h2>
                <p>
                  Si aún no has donado para participar
                  <br /> ingresa aquí
                </p>
              </div>
              <div className="contentDonateHome">
                <Donate />
              </div>
              <div className="contentGirl">
                <img className="girl" src={girl} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PreLanding;
