import React from 'react';
// Images
import logoHeader from '../../assets/images/logo_header.png';
import logoHeaderInv from '../../assets/images/logo_header_inv.png';

const Header = (props) => (
    <header className="header">
        <div className="contentHeader">
            <a href="https://www.caracoltv.com/" target='_blank'>
                <img src={logoHeader} alt="Unicef" className='logoBlue' />
                <p>VOLVER A CARACOL TV</p>
            </a>
            {/* <img src={logoHeaderInv} alt="Unicef" className='logoWhite' /> */}
        </div>
    </header>
)

export default Header;