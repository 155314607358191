import React from 'react';
import logoFooter from '../../assets/images/logo_caracol.png';
import logoUnicef from '../../assets/images/logo_unicef.png';
const Footer = (props) => {
    return (
        <footer className="footer">
            <div className="contentFooter">
                <img src={logoFooter} alt="Unicef" className="logoCaracol" />
                <img src={logoUnicef} alt="Unicef" className="logoUnicef" />
            </div>
        </footer>
    );
};

export default Footer;