import React from "react";
import Service from "../../api/Service";
import { Button, CircularProgress } from "@mui/material";
import { Navigate } from "react-router-dom";
import Cookies from "universal-cookie";
import ModalCustom from "../../components/Modal/ModalCustom";
import UserSesion from "../../components/User/UserSesion";
import logo from "../../assets/images/logov2.png";
import childs from "../../assets/images/childs.png";
import Donate from "../../components/Donate/Donate";
import { globalVariables } from "../../const";
// DFP
import { AdSlot, DFPManager } from "react-dfp";

const cookies = new Cookies();
const service = new Service();
let currentStateVote = null;

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.captcha = null;
    this.refScroll = React.createRef();
    this.refScrollTop = React.createRef();
    this.timer = null;
  }

  state = {
    loading: false,
    participants: null,
    user: null,
    activeVote: null,
    displayModal: false,
    stateModal: null,
    loadingVote: false,
    statusVote: false,
    chekingVote: false,
    gCaptchaResponse: null,
    unMount: false,
    statusPoll: false,
    activeUpdatedAt: null,
    lastUpdatedAt: null,
  };

  scrollToMove = (value) => {
    if (value === "participants" && !this.state.statusVote) {
      setTimeout(() => {
        try {
          this.refScroll.current.scrollIntoView({ behavior: "smooth" });
        } catch (error) {
          console.log("Ha ocurrido un error: ", error);
        }
      }, 1000);
    } else {
      try {
        this.refScrollTop.current.scrollIntoView({ behavior: "smooth" });
      } catch (error) {
        console.log("Ha ocurrido un error: ", error);
      }
    }
  };

  //** To active o inactive vote */
  getServiceVote = async () => {
    currentStateVote = this.state.activeVote;
    /* if (this.state.lastUpdatedAt !== this.state.activeUpdatedAt) {
      await this.getStatusVote();
      this.setState({
        lastUpdatedAt: this.state.activeUpdatedAt,
      });
    } */
    const service = new Service();
    const participantsResponse = await service.getServicesVote();
    if (participantsResponse !== "error") {
      await this.setState({
        participants: participantsResponse,
        loading: false,
        activeVote: participantsResponse.status,
        activeUpdatedAt: participantsResponse.updatedAt,
      });
      if (this.state.lastUpdatedAt !== participantsResponse.updatedAt) {
        this.setState({
          chekingVote: true,
        });
        await this.getStatusVote();
        this.setState({
          lastUpdatedAt: this.state.activeUpdatedAt,
        });
      }
      if (currentStateVote !== this.state.activeVote) {
        this.getStatusVote();
      }
    } else {
      this.setState({
        activeVote: false,
        loading: false,
      });
    }
  };

  getUser = async () => {
    const token = cookies.get("token");
    const userInfo = await service.getServicesAuth("account/profile", token);
    /* console.log("token", userInfo, token); */
    this.setState({
      user: userInfo.item,
    });
  };

  logout = () => {
    cookies.remove("token", { path: "/", secure: true });
    const getBody = document.querySelectorAll("Body");
    getBody.forEach((item) => item.classList.remove("loged"));
    const getHeaderId = document.querySelectorAll(".header");
    getHeaderId.forEach((item) => item.classList.remove("active"));
  };

  getWebSocket = () => {
    try {
      const token = cookies.get("token");
      const ws = new WebSocket(
        `wss://bjs5ainxc0.execute-api.us-east-1.amazonaws.com/uat/?token=${token}&pollId=${this.state.participants.id}`,
        []
      );
      ws.onopen = (item) => {
        console.log("OP WS", item);
      };
      ws.onmessage = async (response) => {
        await this.getServiceVote();
        await this.getStatusVote();
        const data = JSON.parse(response.data);
        this.setState({
          activeVote: data.status,
        });
      };
      ws.onerror = (error) => {};
      ws.onclose = (response) => {};
    } catch (error) {
      console.log("Ha ocurrido un error: ", error);
    }
  };

  getStatusVote = async () => {
    try {
      const token = cookies.get("token");
      const statusVote = await service.checkVote(
        token,
        this.state.participants.id
      );
      this.setState({
        statusVote: statusVote.status,
        chekingVote: false,
      });
    } catch (error) {
      console.log("Ha ocurrido un error: ", error);
    }
  };

  updateServiceVote = async () => {
    this.getServiceVote().then(() => {
      if (!this.state.unMount) {
        this.timer = setTimeout(() => {
          this.updateServiceVote();
        }, 2000);
      }
    });
  };

  voteHandle = async (competitorId) => {
    /**
     * Boolean
     * this.status.statusVote ? 'No ha votado' : 'Votó';
     */
    if (!this.state.statusVote) {
      this.setState({
        loadingVote: true,
      });
      const parameters = {
        pollId: this.state.participants.id,
        pollItemId: competitorId,
      };
      const token = cookies.get("token");
      const sendVote = await service.postVote(parameters, token);
      if (sendVote !== "error") {
        this.setState({
          displayModal: true,
          stateModal: 1,
          loadingVote: false,
        });
        this.getStatusVote();
      } else if (sendVote === "error") {
        this.setState({
          displayModal: true,
          stateModal: 2,
          loadingVote: false,
        });
        this.getStatusVote();
      }
      this.scrollToMove("vote");
    }
  };

  closeModalHandler = () => {
    this.setState({
      displayModal: false,
    });
  };

  async componentDidMount() {
    if (cookies.get("token") !== undefined || cookies.get("token") === "") {
      this.setState({
        loading: true,
        unMount: false,
      });
      await this.getServiceVote();
      this.setState({
        loading: false,
      });
      this.updateServiceVote();
      await this.getStatusVote();
      this.getUser();
      // this.getWebSocket();
      if (!this.state.statusVote) {
        this.scrollToMove("participants");
      }
      const getBody = document.querySelectorAll("Body");
      getBody.forEach((item) => item.classList.add("loged"));
      const getHeaderId = document.querySelectorAll(".header");
      getHeaderId.forEach((item) => item.classList.add("active"));
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  openModalVideoPop = () => {
    alert("ok");
    this.setState({
      openVideoPop: true,
    });
  };

  render() {
    if (this.state.loading) {
      return (
        <div className="loadingHome">
          <CircularProgress size={50} sx={{ color: "fff" }} />
        </div>
      );
    } else if (
      cookies.get("token") !== undefined ||
      cookies.get("token") === ""
    ) {
      if (this.state.activeVote === 1) {
        return (
          <>
            <UserSesion {...this.state.user} logout={this.logout} />
            <ModalCustom
              status={this.state.stateModal}
              open={this.state.displayModal}
              close={this.closeModalHandler}
            />
            <div className="wrapHome">
              <div
                className={`mainHome ${this.state.statusVote ? "vote" : ""} ${
                  this.state.chekingVote ? "cheking" : ""
                }`}
              >
                <div className="contentLogos">
                  <img className="logo" src={logo} />
                </div>
                <div className="contentContest">
                  <h1>{this.state.participants.title}</h1>
                  <p ref={this.refAmbassador}>
                    {this.state.participants.description}
                  </p>
                </div>
                <div className="contentAmbassador">
                  <div
                    className="messageVote vote"
                    style={{
                      display: this.state.statusVote ? "flex" : "none",
                    }}
                  >
                    <h2>YA PARTICIPASTE</h2>
                    <p>Recuerda que solo puedes votar una vez por juego.</p>
                  </div>

                  {this.state.participants.items.map((item) => {
                    return (
                      <div className="itemAmbassador">
                        <div className="contentImage">
                          <div
                            className="imageBack"
                            style={{
                              width: 180,
                              height: 180,
                              backgroundImage: `url('${globalVariables.baseUrl}${item.image}')`,
                            }}
                          ></div>
                        </div>
                        <div className="nameAmbasser" style={{ marginTop: 10 }}>
                          {item.label}
                        </div>
                        <Button
                          className="voteButton"
                          onClick={() => this.voteHandle(item.id)}
                          variant="contained"
                          color="primary"
                          style={{ marginTop: 10 }}
                        >
                          VOTA
                        </Button>
                      </div>
                    );
                  })}
                  <div className="dfpHome">
                    <AdSlot
                      sizes={[
                        [300, 250],
                        [300, 600],
                      ]}
                      adUnit="caracoltv/voyportijuegapormi"
                    />
                  </div>
                </div>
              </div>
              <div className="pautaHomeDesktop">
                <AdSlot
                  sizes={[
                    [300, 600],
                    [300, 600],
                  ]}
                  adUnit="caracoltv/voyportijuegapormi"
                />
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <UserSesion {...this.state.user} logout={this.logout} />
            <div className="wrapHome">
              <div
                className={`mainHome ${this.state.statusVote ? "vote" : ""} ${
                  this.state.chekingVote ? "cheking" : ""
                }`}
              >
                <div className="contentLogos">
                  <img className="logo" src={logo} />
                </div>
                <div className="contentContest">
                  <h1>
                    {this.state.participants?.title ?? "VOTACIONES UNICEF"}
                  </h1>
                  <p ref={this.refAmbassador}>
                    {this.state.participants?.description ??
                      "Próximamente podras apoyar."}
                  </p>
                </div>
                <div className="contentAmbassador">
                  <div
                    className="messageVote messageBeforeVoting vote"
                    style={{
                      display: !this.state.activeVote ? "flex" : "none",
                    }}
                  >
                    <h2>ACTIVIDAD SIN INICIAR</h2>
                    <p>Prepárate porque muy pronto podrás votar.</p>
                    <img className="childs" src={childs} />
                  </div>
                  <div className="dfpHome">
                    <AdSlot
                      sizes={[
                        [300, 250],
                        [300, 600],
                      ]}
                      adUnit="caracoltv/voyportijuegapormi"
                    />
                  </div>
                </div>
              </div>
              <div className="pautaHomeDesktop">
                <AdSlot
                  sizes={[
                    [300, 600],
                    [300, 600],
                  ]}
                  adUnit="caracoltv/voyportijuegapormi"
                />
              </div>
            </div>
          </>
        );
      }
    } else {
      return <Navigate to="/login" />;
    }
  }
}

export default Home;
