import React from "react";
import { Button } from "@mui/material";

const Donate = () => {
  return (
    <div className="wantDonate" style={{ textAlign: "center" }}>
      {/* <h1>¡Quiero donar!</h1>
            <p>
                ¿Deseas realizar una nueva donación?<br />Sigue el siguiente enlace
                    </p> */}
      <Button
        fullWidth
        className="Button lessHeight"
        variant="contained"
        color="primary"
        onClick={() => {
          window.open(
            "https://donaciones.unicef.org.co/donar?utm_campaign=caracol-2024&utm_medium=caracol-web&utm_source=caracol-tv&utm_term=web-concurso&utm_content=",
            "_blank"
          );
        }}
      >
        ¡Quiero donar!
      </Button>
    </div>
  );
};

export default Donate;
